import React from 'react';
import logo from '../img/banner.png';
// import logo2 from '../img/logo2.png';
import logo2 from '../img/shihelogo.png';
// import logo2 from '../img/qgzs1.png';
import wechat from '../img/wechat2.jpg';
import custmgt from '../img/custmgt.png';
import jxc from '../img/jxc.png';
import salelead from '../img/salelead.png';
import web1 from '../img/web1.png';
import web2 from '../img/web2.png';
import selfdefine from '../img/selfdefine.png';
import finance from '../img/finance.png';
import telemarketting from '../img/telemarketting.png';
import tongji from '../img/tongji.png';
import oa1 from '../img/oa1.png';
import setting from '../img/setting.png';
import flow from '../img/flow.png';
import vist from '../img/vist.png';
import order from '../img/order.png';


export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
          logo2,
      // 'https://gw.alipayobjects.com/zos/basement_prod/b30cdc2a-d91c-4c78-be9c-7c63b308d4b3.svg',

  },
  Menu: {
    className: 'header3-menu',
    children: [
      // {
      //   name: 'item0',
      //   className: 'header3-item',
      //   children: {
      //     href: '/',
      //     children: [{ children: '主页', name: 'text' }],
      //   },
      //   subItem: [
      //     {
      //       name: 'sub0',
      //       className: 'item-sub',
      //       children: {
      //         className: 'item-sub-item',
      //         children: [
      //           {
      //             name: 'image0',
      //             className: 'item-image',
      //             children:
      //               'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
      //           },
      //           {
      //             name: 'title',
      //             className: 'item-title',
      //             children: 'Ant Design',
      //           },
      //           {
      //             name: 'content',
      //             className: 'item-content',
      //             children: '企业级 UI 设计体系',
      //           },
      //         ],
      //       },
      //     },
      //     {
      //       name: 'sub1',
      //       className: 'item-sub',
      //       children: {
      //         className: 'item-sub-item',
      //         children: [
      //           {
      //             name: 'image0',
      //             className: 'item-image',
      //             children:
      //               'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
      //           },
      //           {
      //             name: 'title',
      //             className: 'item-title',
      //             children: 'Ant Design',
      //           },
      //           {
      //             name: 'content',
      //             className: 'item-content',
      //             children: '企业级 UI 设计体系',
      //           },
      //         ],
      //       },
      //     },
      //   ],
      // },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/Login1',
          children: [{ children: '登录', name: 'text' }],
        },
      },
      // { name: 'item9', className: 'header3-item', a: { children: '登录9', href: '/Login' } },
      // {
      //   name: 'item2',
      //   className: 'header3-item',
      //   children: {
      //     href: '/Page2',
      //     children: [{ children: '注册', name: 'text' }],
      //   },
      // },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '/Register1',
          children: [{ children: '注册', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: (
      <span>
        <p>CRM软件</p>
      </span>
    ),
  },
  content: {
    className: 'banner0-content',
    children: '一体化的企业管理软件，功能包括：CRM+OA+ERP+销售+流程+财务+售后+发票+任务+通告管理。支持SAAS+私有化部署，支持定制开发！',
  },

  // button: { className: 'banner0-button', children: 'Learn More' },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    // children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
    children: web2,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Web端' },
  content: {
    className: 'content2-content',
    children:
      '所有的设置与业务处理均可在电脑浏览器上完成，无需下载安装程序。支持各种浏览器，资源缓存在本地，访问方便快捷。',
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: wechat,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: '微信小程序端' },
  content: {
    className: 'content1-content',
    children:
      '方便业务员在外随时提交业务数据，领导实时了解业务趋势，助力提升销售业绩。请扫描小程序码，或微信搜一搜"时和CRM"。 ',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>软件特色</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于SAAS架构设计，部署在阿里云服务器，安全可靠！',
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>免费试用</p>
                </span>
              ),
            },
            { name: 'content', children: '10人以下全功能免费试用一周（web端+小程序端），全力支持中小微企业发展。' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>定制化开发</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: '除了注册即可试用的SAAS模式，我们还支持定制化开发，满足客户的个性化需求，可本地化部署，保障数据安全性。',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                  'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>流程管理</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: '支持可视化的流程设置，可根据客户需求定制流程，流程审批方便快捷，功能强大。',
            },
          ],
        },
      },
      // {
      //   name: 'block3',
      //   className: 'content0-block',
      //   md: 8,
      //   xs: 24,
      //   children: {
      //     className: 'content0-block-item',
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'content0-block-icon',
      //         children:
      //             'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
      //       },
      //       {
      //         name: 'title',
      //         className: 'content0-block-title',
      //         children: (
      //             <span>
      //             <p>销售线索</p>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content',
      //         children: '沉淀产品接入效率和运营小二工作效率数据',
      //       },
      //     ],
      //   },
      // },
      // {
      //   name: 'block4',
      //   className: 'content0-block',
      //   md: 8,
      //   xs: 24,
      //   children: {
      //     className: 'content0-block-item',
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'content0-block-icon',
      //         children:
      //             'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
      //       },
      //       {
      //         name: 'title',
      //         className: 'content0-block-title',
      //         children: (
      //             <span>
      //             <p>销售线索</p>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content',
      //         children: '沉淀产品接入效率和运营小二工作效率数据',
      //       },
      //     ],
      //   },
      // },
      // {
      //   name: 'block5',
      //   className: 'content0-block',
      //   md: 8,
      //   xs: 24,
      //   children: {
      //     className: 'content0-block-item',
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'content0-block-icon',
      //         children:
      //             'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
      //       },
      //       {
      //         name: 'title',
      //         className: 'content0-block-title',
      //         children: (
      //             <span>
      //             <p>销售线索</p>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content',
      //         children: '沉淀产品接入效率和运营小二工作效率数据',
      //       },
      //     ],
      //   },
      // },
    ],
  },
};
// export const Feature00DataSource = {
//   wrapper: { className: 'home-page-wrapper content0-wrapper' },
//   page: { className: 'home-page content0' },
//   OverPack: { playScale: 0.3, className: '' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'title',
//         children: (
//           <span>
//             <p>微信小程序端</p>
//           </span>
//         ),
//       },
//     ],
//   },
//   childWrapper: {
//     className: 'content0-block-wrapper',
//     children: [
//       {
//         name: 'block0',
//         className: 'content0-block',
//         md: 8,
//         xs: 24,
//         children: {
//           className: 'content0-block-item',
//           children: [
//             {
//               name: 'image',
//               className: 'content0-block-icon',
//               children:
//                 'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
//             },
//             {
//               name: 'title',
//               className: 'content0-block-title',
//               children: '一站式业务接入',
//             },
//             { name: 'content', children: '支付、结算、核算接入产品效率翻四倍' },
//           ],
//         },
//       },
//       {
//         name: 'block1',
//         className: 'content0-block',
//         md: 8,
//         xs: 24,
//         children: {
//           className: 'content0-block-item',
//           children: [
//             {
//               name: 'image',
//               className: 'content0-block-icon',
//               children:
//                 'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
//             },
//             {
//               name: 'title',
//               className: 'content0-block-title',
//               children: '一站式事中风险监控',
//             },
//             {
//               name: 'content',
//               children: '在所有需求配置环节事前风险控制和质量控制能力',
//             },
//           ],
//         },
//       },
//       {
//         name: 'block2',
//         className: 'content0-block',
//         md: 8,
//         xs: 24,
//         children: {
//           className: 'content0-block-item',
//           children: [
//             {
//               name: 'image',
//               className: 'content0-block-icon',
//               children:
//                 'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
//             },
//             {
//               name: 'title',
//               className: 'content0-block-title',
//               children: '一站式数据运营',
//             },
//             {
//               name: 'content',
//               children: '沉淀产品接入效率和运营小二工作效率数据',
//             },
//           ],
//         },
//       },
//     ],
//   },
// };
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '主要功能',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '上百项实用功能，期待您登录体验，还可定制哦！',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            // children:
            //     'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
            children: custmgt,

          },

          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '客户管理' },
          content: {
            className: 'content3-content',
            children:
              '客户管理、客户公海、条件搜索、客户标签、客户（领取、分配、移交）、客户电话直拨及统计、名⽚识别。助您维系客户关系，及时跟进不丢单。',
          },
        },
      },

      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: order,
              // 'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '销售管理' },
          content: {
            className: 'content3-content',
            children:
              '销售线索、客户信息、客户标签、销售订单、销售排名、销售统计、趋势图、客户拜访、拜访总结、电话销售。销售趋势⼀⽬了然，助⼒企业提升销售业绩',
          },
        },
      },



      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: oa1,
              // 'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'OA' },
          content: {
            className: 'content3-content',
            children:
              '满足企业员工考勤打卡、请假申请、出差申请、离职申请、工作报告、私人笔记、自定义表单的日常需求，并可根据需要扩展更多的OA功能。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: jxc,
              // 'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '进销存' },
          content: {
            className: 'content3-content',
            children:
              '供应商管理（新增供应商、供应商查询），采购管理（采购申请、采购查询），库存管理（商品入库、入库查询、出库申请、出库查询、出库打印、库存盘点、盘点查询、库存查询）、小程序扫码入库、仓库设置、库存商品设置。',
          },
        },
      },
      {
        name: 'block6',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: flow,
              // 'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '流程管理' },
          content: {
            className: 'content3-content',
            children:
              '流程设置（可视化流程绘制）、流程申请（可提交流程附件）、流程审批（可认领任务、待办任务、进行中流程、已完结流程）、流程抄送、流程查询（当前审批节点、审批详情）。',
          },
        },
      },

      {
        name: 'block7',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: finance,
            // 'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '财务管理' },
          content: {
            className: 'content3-content',
            children:
                '包括：收入单（对客户的多个收入项）、支出单（对供应商的多个支出项）、收款单（对客户的分期收款）、付款单（对供应商的分期付款）。更多功能陆续上新中。',
          },
        },
      },

      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: setting,
            // 'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '系统设置' },
          content: {
            className: 'content3-content',
            children:
                '员工账号设置、上下级关系设置、角色设置、权限设置、上下班时间设置、考勤地址设置、自定义表单设置、产品库设置、仓库设置、库存商品设置、流程设置、标签设置、有效期及剩余账号。',
          },
        },
      },



      // {
      //   name: 'block18',
      //   className: 'content3-block',
      //   md: 8,
      //   xs: 24,
      //   children: {
      //     icon: {
      //       className: 'content3-icon',
      //       children: telemarketting,
      //       // 'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
      //     },
      //     textWrapper: { className: 'content3-text' },
      //     title: { className: 'content3-title', children: '电话销售' },
      //     content: {
      //       className: 'content3-content',
      //       children:
      //           '手机端直接拨打客户电话，及时生成电话记录，可查看电销统计，发掘最勤快和高效的业务员！',
      //     },
      //   },
      // },


    ],
  },
};
// export const Pricing20DataSource = {
//   wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
//   page: { className: 'home-page pricing2' },
//   OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
//   titleWrapper: {
//     className: 'pricing2-title-wrapper',
//     children: [
//       {
//         name: 'title',
//         children: (
//           <span>
//             <p>免费体验账号</p>
//           </span>
//         ),
//         className: 'pricing2-title-h1',
//       },
//     ],
//   },
//   Table: {
//     name: 'tabsTitle',
//     size: 'default',
//     className: 'pricing2-table',
//     columns: {
//       children: [
//         {
//           dataIndex: 'name',
//           key: 'name',
//           name: 'empty',
//           childWrapper: {
//             children: [
//               { name: 'name', children: ' ' },
//               { name: 'content', children: ' ' },
//             ],
//           },
//         },
//         {
//           dataIndex: 'free',
//           key: 'free',
//           name: 'free',
//           childWrapper: {
//             className: 'pricing2-table-name-block',
//             children: [
//               {
//                 name: 'name',
//                 className: 'pricing2-table-name',
//                 children: (
//                   <span>
//                     <p>登录账号</p>
//                   </span>
//                 ),
//               },
//               // {
//               //   name: 'content',
//               //   className: 'pricing2-table-money',
//               //   children: (
//               //     <span>
//               //       <p>
//               //         <br />
//               //       </p>
//               //     </span>
//               //   ),
//               // },
//               // { name: 'button', children: { href: '#', children: '免费试用' } },
//             ],
//           },
//         },
//         {
//           dataIndex: 'basic',
//           key: 'basic',
//           name: 'basic',
//           childWrapper: {
//             className: 'pricing2-table-name-block',
//             children: [
//               {
//                 name: 'name',
//                 className: 'pricing2-table-name',
//                 children: '登录密码',
//               },
//               // {
//               //   name: 'content',
//               //   className: 'pricing2-table-money',
//               //   children: '¥550',
//               // },
//               // { name: 'button', children: { href: '#', children: '立即购买' } },
//             ],
//           },
//         },
//         {
//           dataIndex: 'pro',
//           key: 'pro',
//           name: 'pro',
//           childWrapper: {
//             className: 'pricing2-table-name-block',
//             children: [
//               {
//                 name: 'name',
//                 className: 'pricing2-table-name',
//                 children: '部门',
//               },
//               // {
//               //   name: 'content',
//               //   className: 'pricing2-table-money',
//               //   children: '¥2,200',
//               // },
//               // {
//               //   name: 'button',
//               //   children: { href: '#', type: 'primary', children: '立即购买' },
//               // },
//             ],
//           },
//         },
//         {
//           dataIndex: 'unlimited',
//           key: 'unlimited',
//           name: 'unlimited',
//           childWrapper: {
//             className: 'pricing2-table-name-block',
//             children: [
//               {
//                 name: 'name',
//                 className: 'pricing2-table-name',
//                 children: '职务',
//               },
//               // {
//               //   name: 'content',
//               //   className: 'pricing2-table-money',
//               //   children: '¥5,600',
//               // },
//               // { name: 'button', children: { href: '#', children: '立即购买' } },
//             ],
//           },
//         },
//       ],
//     },
//     dataSource: {
//       children: [
//         {
//           name: 'list0',
//           children: [
//             {
//               className: 'pricing2-table-content-name',
//               name: 'name',
//               children: '1',
//             },
//             {
//               children: (
//                 <span>
//                   <span>
//                     <p>zhaoyi</p>
//                   </span>
//                 </span>
//               ),
//               name: 'content0',
//               className: 'pricing2-table-content',
//             },
//             {
//               children: '123',
//               name: 'content1',
//               className: 'pricing2-table-content',
//             },
//             {
//               children: '总经办',
//               name: 'content2',
//               className: 'pricing2-table-content',
//             },
//             {
//               children: '总经理',
//               name: 'content3',
//               className: 'pricing2-table-content',
//             },
//           ],
//         },
//         {
//           name: 'list1',
//           children: [
//             {
//               className: 'pricing2-table-content-name',
//               name: 'name',
//               children: '2',
//             },
//             {
//               children: (
//                 <span>
//                   <p>longer</p>
//                 </span>
//               ),
//               name: 'content0',
//               className: 'pricing2-table-content',
//             },
//             {
//               children: '123',
//               name: 'content1',
//               className: 'pricing2-table-content',
//             },
//             {
//               children: '销售部',
//               name: 'content2',
//               className: 'pricing2-table-content',
//             },
//             {
//               children: '销售总监',
//               name: 'content3',
//               className: 'pricing2-table-content',
//             },
//           ],
//         },
//         {
//           name: 'list2',
//           children: [
//             {
//               className: 'pricing2-table-content-name',
//               name: 'name',
//               children: '3',
//             },
//             {
//               name: 'content0',
//               children: 'jiangsan',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content1',
//               children: '123',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content2',
//               children: '销售部',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content3',
//               children: '销售经理',
//               className: 'pricing2-table-content',
//             },
//           ],
//         },
//         {
//           name: 'list3',
//           children: [
//             {
//               className: 'pricing2-table-content-name',
//               name: 'name',
//               children: '4',
//             },
//             {
//               children: 'chensi',
//               name: 'content0',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content1',
//               children: '123',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content2',
//               children: '采购部',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content3',
//               children: '采购总监',
//               className: 'pricing2-table-content',
//             },
//           ],
//         },
//         {
//           name: 'list4',
//           children: [
//             {
//               className: 'pricing2-table-content-name',
//               name: 'name',
//               children: '5',
//             },
//             {
//               name: 'content0',
//               children: 'zhaowu',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content1',
//               children: '123',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content2',
//               children: '仓管部',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content3',
//               children: '仓管主管',
//               className: 'pricing2-table-content',
//             },
//           ],
//         },
//         {
//           name: 'list5',
//           children: [
//             {
//               className: 'pricing2-table-content-name',
//               name: 'name',
//               children: '6',
//             },
//             {
//               name: 'content0',
//               children: 'zhangliu',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content1',
//               children: '123',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content2',
//               children: '人事部',
//               className: 'pricing2-table-content',
//             },
//             {
//               name: 'content3',
//               children: '人事专员',
//               className: 'pricing2-table-content',
//             },
//           ],
//         },
//         // {
//         //   name: 'list5',
//         //   children: [
//         //     {
//         //       className: 'pricing2-table-content-name',
//         //       name: 'name',
//         //       children: '系统盘（线性计费）',
//         //     },
//         //     {
//         //       name: 'content0',
//         //       children: '-',
//         //       className: 'pricing2-table-content',
//         //     },
//         //     {
//         //       name: 'content1',
//         //       children: '-',
//         //       className: 'pricing2-table-content',
//         //     },
//         //     {
//         //       name: 'content2',
//         //       children: '-',
//         //       className: 'pricing2-table-content',
//         //     },
//         //     {
//         //       name: 'content3',
//         //       children:
//         //         'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
//         //       className: 'pricing2-table-content',
//         //     },
//         //   ],
//         // },
//       ],
//     },
//   },
// };

export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <div>
          {/*<span>©2019-2023 深圳市时和科技有限公司&nbsp; <a href="http://www.beian.miit.gov.cn/">ICP证号：粤ICP备19140753号</a> &nbsp; </span>*/}
        </div>
      </span>
    // 业务咨询：13760271597，微信号：lihaiming0755

    ),
  },
};
